import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TAlert = {
    message?: string,
    icon: "success" | "error" | "warn" | "info";
    timer?: number;
}

interface IAlertSlice {
    toasts: TAlert[]
}
const initialState: IAlertSlice = {
    toasts: []
}
export const AlertSlice = createSlice({
    name: "alerts",
    initialState,
    reducers: {
        createAlert: (state, action: PayloadAction<TAlert>) => {
            state.toasts.push(
                action.payload
            );
        },
        shiftAlert: (state) => {
            state.toasts.shift();
        }
    },
});

export const selectorAlert = (state: { notifications: IAlertSlice }) => state.notifications
export const { createAlert, shiftAlert } = AlertSlice.actions;

export default AlertSlice.reducer;

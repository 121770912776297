import { Input, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbComponent } from '../../../../../components/BreadcrumbComponent';
import '../../../styles.css';
import * as SystemConfigurationApi from '../../../../../api/SystemConfigurationApi/index';
import { useEffect, useState } from 'react';
import useToast from '../../../../../hook/useToast';

export default function SetUpServeSendEmail() {
    let errorsObj = {
        email: "",
        matKhau: "",
        host: "",
        port: "",
        tenDangNhap: "",
        soLuong: ""
    }
    const navigator = useNavigate();
    const [id, setID] = useState<number>(0);
    const [email, setEmail] = useState<string>("");
    const [tenDangNhap, setTenDangNhap] = useState<string>("");
    const [matKhau, setMatKhau] = useState<string>("");
    const [host, setHost] = useState<string>("");
    const [port, setPort] = useState<number>();
    const [soLuong, setSoLuong] = useState<number>(0);
    const [errors, setErrors] = useState(errorsObj);
    const pushAlert = useToast();

    const fetchCauHinh = async () => {
        try {
            const result = await SystemConfigurationApi.getCauHinhEmail();
            if (result.success) {
                setID(result.data.id)
                setEmail(result.data.email)
                setMatKhau(result.data.mat_khau)
                setHost(result.data.host ?? "")
                setPort(result.data.port)
                setTenDangNhap(result.data.ten_dang_nhap)
                setSoLuong(result.data.so_luong_email)
            }
        } catch (error) {
        }
    }
    const handleSave = async (e: any) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === "" || email === undefined) {
            errorObj.email = "Vui lòng nhập tên email !";
            error = true;
        }
        if (matKhau === "" || matKhau === undefined) {
            errorObj.matKhau = "Vui lòng nhập mật khẩu !";
            error = true;
        }
        if (host === "") {
            errorObj.host = "Vui lòng nhập host !";
            error = true;
        }
        if (!port) {
            errorObj.port = "Vui lòng nhập port !";
            error = true;
        }
        if (tenDangNhap === "") {
            errorObj.tenDangNhap = "Vui lòng nhập tên đăng nhập !";
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return null;
        } else {
            try {
                const result = await SystemConfigurationApi.updateEmail(id, email, matKhau, host, Number(port), tenDangNhap, soLuong);
                if (result.success) {
                    pushAlert("Cập nhật thành công!", "success", 2000)
                    setTimeout(() => {
                        navigator("/system-configuration")
                    }, 2000)
                } else {
                    pushAlert(result.message ?? "Cập nhật không thành công", "error", 2000)
                }
            } catch (error: any) {
                pushAlert(error.message ?? "", "error", 2000)
            }
        }
    };

    useEffect(() => {
        fetchCauHinh()
    }, [])
    return (
        <div>
            <BreadcrumbComponent children='Thiết lập máy chủ gửi email thông báo' title='Cấu hình hệ thống ' />
            <div className='px-6 py-6'>
                <div className={`flex flex-col bg-white rounded-md  pt-2 w-full`}>
                    <div className="border-b-EEEEEE pb-2">
                        <h3 className='text-base font-[600] m-0 px-5'>Thiết lập máy chủ gửi email thông báo</h3>
                    </div>
                    <div className='flex flex-col py-[32px] gap-8 px-5'>
                        <div className='flex items-center justify-between '>
                            <span className='text-[#4B4B4B] text-sm'>Tên email</span>
                            <Input placeholder='Tên' size='large' className='w-[92%]' value={email} onChange={(e) => setEmail(e.target.value)} />
                            {errors.email && (
                                <div className="text-danger fs-12">
                                    {errors.email}
                                </div>
                            )}
                        </div>
                        <div className='flex items-center justify-between '>
                            <span className='text-[#4B4B4B] text-sm'>Mật khẩu</span>
                            <Input.Password 
                                placeholder='Mật khẩu' 
                                size='large' 
                                type='password' 
                                className='w-[92%]' 
                                value={matKhau} 
                                onChange={(e) => setMatKhau(e.target.value)}
                            />
                            {errors.matKhau && (
                                <div className="text-danger fs-12">
                                    {errors.matKhau}
                                </div>
                            )}
                        </div>
                        <div className='flex items-center justify-between '>
                            <span className='text-[#4B4B4B] text-sm'>Host</span>
                            <Input placeholder='Tên' size='large' className='w-[92%]' value={host} onChange={(e) => setHost(e.target.value)} />
                        </div>
                        {errors.host && (
                            <div className="text-danger fs-12">
                                {errors.host}
                            </div>
                        )}
                        <div className='flex items-center justify-between '>
                            <span className='text-[#4B4B4B] text-sm'>Port</span>
                            <Input 
                                type='number' 
                                placeholder='Port' 
                                size='large' 
                                className='w-[92%]' 
                                value={port} 
                                onChange={(e) => setPort(Number(e.target.value))} 
                            />
                        </div>
                        {errors.port && (
                            <div className="text-danger fs-12">
                                {errors.port}
                            </div>
                        )}
                        <div className='flex items-center justify-between '>
                            <span className='text-[#4B4B4B] text-sm'>Tên đăng nhập</span>
                            <Input 
                                placeholder='Tên' 
                                size='large' 
                                className='w-[92%]' 
                                value={tenDangNhap} 
                                onChange={(e) => setTenDangNhap(e.target.value)} 
                            />
                        </div>
                        {errors.tenDangNhap && (
                            <div className="text-danger fs-12">
                                {errors.tenDangNhap}
                            </div>
                        )}
                        <div className='flex items-center justify-between '>
                            <span className='text-[#4B4B4B] text-sm'>Số lượng email tối đa được gửi</span>
                            <Input 
                                placeholder='Số lượng email tối đa được gửi' 
                                size='large' 
                                className='w-[92%]' 
                                value={soLuong} 
                                onChange={(e) => setSoLuong(Number(e.target.value))} 
                            />
                        </div>
                        {errors.soLuong && (
                            <div className="text-danger fs-12">
                                {errors.soLuong}
                            </div>
                        )}
                    </div>
                    <div className='px-5 py-3 custom-shadow flex gap-2'>
                        <button className='bg-white border-EC2D30  text-danger text-base py-1 px-8 rounded-md'
                            onClick={() => navigator('/system-configuration')}>
                            Hủy
                        </button>
                        <button className='bg-[#227CDD] text-white text-base py-1 px-8 rounded-md' onClick={handleSave}>
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </div>


    )
}


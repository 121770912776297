import { ResponseSuccessTotal } from "../axiosClient";
import axiosClientUser, { ResponsePostSuccess, ResponseSuccess } from "../axiosClientUser";

export interface Pagination {
    current: number,
    pageSize: number,
    total: number,
}

export interface LoaiFile {
    id: number,
    loai_file: string,
    file: string,
    image: string,
    tai_lieu: boolean
    bao_cao: boolean
}

export interface CauHinh {
    tailieu: number[]
    baocao: number[]
    dung_luong_tai_lieu: number
    dung_luong_bao_cao: number
    dung_luong_tai_lieu_goc: number
    email: string
}


export interface CauHinhTaiLieu {
    danh_sach: DanhSach[]
    loai_file:DanhSachLoaiFile[]
    dung_luong: number
    dung_luong_goc: number
    so_luong_email: number
  }
  
  export interface CauHinhBaoCao {
    danh_sach: DanhSach[]
    dung_luong: number
  }

  export interface DanhSach {
    id: number
    loai_cau_hinh: number
    loaiFileCauHinh: LoaiFileCauHinh
  }

  export interface DanhSachLoaiFile {
    id: number
    file: string
    image: string
  }



  export interface LoaiFileCauHinh {
    id: number
    file: string
    image: string
  }

  export interface ListLuotTruyCap {
    id: number
    ngay_truy_cap: string
    luottruycap: Luottruycap | null
    ten: string
  }
  
  export interface Luottruycap {
    id: number
    fullname: string
    username: string
  }
  export interface DataLogHoatDong {
    key: number;
    stt: number;
    fullname: string;
    email: string;
    time: string;
    ten: string
}

export interface CapNhatCauHinh {
    tailieu : number[]
    baocao : number[]
    dung_luong_tai_lieu : number
    dung_luong_bao_cao : number
    loai_dung_luong_TL: string
    loai_dung_luong_BC: string
  }

  export interface EmailTemplate {
    id: number
    ten: string
    tieu_de: string
    noi_dung: string
    ngay_tao: Date
    status: boolean
    type: number
  }
  
  export interface CauHinhEmail {
    id: number
    email: string
    email_noti: string
    mat_khau: string
    host: string
    port: number
    ten_dang_nhap: string
    so_luong_email: number
  }
  export interface Param {
    title: string
    value: string
  }

export interface dataParam{
    dataDocument: Param[], 
    dataReport: Param[],
    dataDuThao: Param[],
}



export const getListLoaiFile = async () => {
    const url = `cauhinh/danh-sach-loai-file`;
    const result: ResponseSuccess<LoaiFile[]> = await axiosClientUser.get(url,);
    return result;
}

export const getCauHinh = async () => {
    const url = `cauhinh/danh-sach`;
    const result: ResponseSuccess<CauHinh> = await axiosClientUser.get(url,);
    return result;
}

export const getCauHinhTaiLieu = async () => {
    const url = `cauhinh/tai-lieu`;
    const result: ResponseSuccess<CauHinhTaiLieu> = await axiosClientUser.get(url,);
    return result;
}

export const getCauHinhBaoCao = async () => {
    const url = `cauhinh/bao-cao`;
    const result: ResponseSuccess<CauHinhBaoCao> = await axiosClientUser.get(url,);
    return result;
}

export const getCauHinhEmail = async () => {
    const url = `cauhinh/cau-hinh-email`;
    const result: ResponseSuccess<CauHinhEmail> = await axiosClientUser.get(url,);
    return result;
}


export const getLogHoatDong = async (PageNum: number, PageSize: number, search: string, ngayBatDau: string, ngayKetThuc: string) => {
    const url = `luottruycap/danh-sach`;
    const params = { PageNum, PageSize, search, ngayBatDau, ngayKetThuc}
    const result: ResponseSuccessTotal<ListLuotTruyCap> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}

export const updateCauHinh = async (
    tailieu: number[],
    baocao : number[],
    dung_luong_tai_lieu : number,
    dung_luong_bao_cao : number,
    email: string,
    dung_luong_tai_lieu_goc : number,
) => {
    const url = `cauhinh/cap-nhat-cau-hinh`;
    const params = {
        tailieu,
        baocao,
        dung_luong_tai_lieu,
        dung_luong_bao_cao,
        email,
        dung_luong_tai_lieu_goc
    }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}

export const getListEmailTemplate = async () => {
    const url = `cauhinh/email-thong-bao`;
    const result: ResponseSuccess<EmailTemplate[]> = await axiosClientUser.get(url,);
    return result;
}


export const detailEmailTemplate = async (id: number) => {
    const url = `cauhinh/chi-tiet-email-thong-bao`;
    const params = { id }
    const result: ResponseSuccess<EmailTemplate> = await axiosClientUser.get(url, { params });
    return result;
}

export const updateEmailTemplate = async (
    id: number,
    tieu_de : string,
    noi_dung : string,
) => {
    const url = `cauhinh/cap-nhat-email-thong-bao`;
    const params = {
        id,
        tieu_de,
        noi_dung,
    }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}

export const updateEmail = async (
    id: number,
    email: string,
    mat_khau : string,
    host : string,
    port: number,
    ten_dang_nhap: string,
    so_luong_email: number
) => {
    const url = `cauhinh/cap-nhat-cau-hinh-email`;
    const params = {
        id,
        email,
        mat_khau,
        host,
        port,
        ten_dang_nhap,
        so_luong_email
    }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}

export const updateStatus = async (
    id: number,
    status: boolean,
) => {
    const url = `cauhinh/cap-nhat-trang-thai`;
    const params = {
        id,
        status,
    }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}

export const getListParam = async () => {
    const url = `cauhinh/danh-sach-param`;
    const result: ResponseSuccess<dataParam> = await axiosClientUser.get(url,);
    return result;
}
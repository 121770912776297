import { notFound_404 } from "../imgExport"

const Page404 = () => {
  return (
    <div className='flex justify-center items-center row p-0 m-0' style={{ height: '100%', width: '100%' }}>
      <div className="flex flex-col items-center  justify-center col-md-4  gap-12 w-[40%]">
        <div className="col-8">
          <img className='img' src={notFound_404} alt="" />
        </div>
        <div className="flex flex-col items-center  justify-center gap-2">
          <h3 className='text-[#344054] font-[600] text-3xl'>404 - KHÔNG TÌM THẤY TRANG</h3>
          <span className='text-[#98A2B3] text-sm mt-1 text-center'>Hãy kiểm tra URL và đảm bảo rằng bạn đã nhập đúng. Nếu URL chính xác, hãy kiểm tra cấu hình máy chủ và đảm bảo rằng tệp tương ứng tồn tại.</span>
        </div>
      </div>
    </div>
  )
}
export default Page404
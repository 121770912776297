import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import ToastGlobal from "../components/ToastGlobal";
import LayOut from "./Layout";
import LayoutPortal from "./LayoutPortal";
import LoadingGlobal from "../components/LoadingGlobal";
import FailReload from "../pages/FailReload/FailReload";
import "./styles.css";
import PrivateRoute from "./PrivateRouter";
import SystemConfiguration from "../pages/System/Settiing";
import SetUpServeSendEmail from "../pages/System/Settiing/component/Setup/SetUpServeSendEmail";
import SetUpNotiEmail from "../pages/System/Settiing/component/Setup/SetUpNotiEmail";
import Page404 from "../components/Page404/Page404";
import LayoutQLTD from "./LayoutQLTD";

const IssuingUnit = lazy(() => import("../pages/Category/IssuingUnit/IssuingUnit"));
const ManagementDocument = lazy(() => import("../pages/Category/ManagementDocument/ManagementDocument"));
const ManagementReport = lazy(() => import("../pages/Category/Report/ManagementReport"));
const ManagementReportAdd = lazy(() => import("../pages/Category/Report/ReportAdd/ManagementReportAdd"));
const ManagementReportUpdate = lazy(() => import("../pages/Category/Report/ReportUpdate/ManagementReportUpdate"));
const DocumentCatalog = lazy(() => import("../pages/Category/DocumentCatalog/List"));
const DocumentCatalogAdd = lazy(() => import("../pages/Category/DocumentCatalog/ReportAdd/ManagementReportAdd"));
const DocumentCatalogUpdate = lazy(() => import("../pages/Category/DocumentCatalog/ReportUpdate/ManagementReportUpdate"));
const NationwideUnit = lazy(() => import("../pages/Category/NationwideUnit/NationwideUnit"));
const DepartmentalUnit = lazy(() => import("../pages/Category/DepartmentalUnit/DepartmentalUnit"));
const RegionalUnit = lazy(() => import("../pages/Category/RegionalUnit/RegionalUnit"));
const ManagementUnit = lazy(() => import("../pages/Category/Unit/ManagementUnit"));
const DocumentListAdd = lazy(() => import("../pages/DocumentList/DocumentAdd/DocumentListAdd"));
const DocumentList = lazy(() => import("../pages/DocumentList/DocumentList/DocumentList"));
const DocumentListUpdate = lazy(() => import("../pages/DocumentList/DocumentUpadte/DocumentListUpdate"));
const CategoryFAQ = lazy(() => import("../pages/FAQ/CategoryFAQ/CategoryFAQ"));
const ManagementFAQ = lazy(() => import("../pages/FAQ/ManegementFAQ/ManagemenFAQ"));
const HomePage = lazy(() => import("../pages/HomePage/HomePage"));
const HomePortal = lazy(() => import("../pages/HomePage/Portal/HomePortal"));
const NewsPage = lazy(() => import("../pages/HomePage/News/News"));
const TagNews = lazy(() => import("../pages/HomePage/News/TagNews"));
const NewsDetailPage = lazy(() => import("../pages/HomePage/News/NewsDetail"));
const DetailNewsPage = lazy(() => import("../pages/HomePage/News/DetailNews"));
const ReportAdd = lazy(() => import("../pages/Report/UploadReport/ReportAdd/ReportAdd"));
const ReportUpdate = lazy(() => import("../pages/Report/UploadReport/ReportUpdate/ReportUpdate"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(resolve, 500)
  })
    .then(() => import('../pages/LogIn'));
});
const ReportStatistics = lazy(() => import("../pages/Report/ReportStatistics/ReportStatistics"));
const UploadReport = lazy(() => import("../pages/Report/UploadReport/UploadReport"));
const Role = lazy(() => import("../pages/System/Role/Role"));
const LogActivity = lazy(() => import("../pages/System/LogActivity/LogActivity"));
const RoleAdd = lazy(() => import("../pages/System/Role/RoleAdd"));
const RoleInfomation = lazy(() => import("../pages/System/Role/RoleInfomation"));
const UserList = lazy(() => import("../pages/System/User/UserList"));
const UserGroupList = lazy(() => import("../pages/System/UserGroup/UserGroupList"));
const UserGroupAdd = lazy(() => import("../pages/System/UserGroup/UserGroupAdd"));
const UserGroupUpdate = lazy(() => import("../pages/System/UserGroup/UserGroupUpdate"));
const UserGroupAddExcel = lazy(() => import("../pages/System/UserGroup/UserGroupAddExcel"));
const HomePortalView = lazy(() => import("../pages/HomePage/Portal/HomePortalView"));
const InternalDocuments = lazy(() => import("../pages/InternalDocuments/InternalDocuments"));
const SearchDocument = lazy(() => import("../pages/SearchDocument/SearchDocument"));
const Personal = lazy(() => import("../pages/Personal/index"));
const PersonalUpdate = lazy(() => import("../pages/Personal/PersonalUpdate"));
const PersonalPortal = lazy(() => import("../pages/PersonalPortal"));
const PromulgatingUnit = lazy(() => import("../pages/Category/PromulgatingUnit/PromuIgatingUnit"));
const ManagementNews = lazy(() => import("../pages/Category/CategoryNews/CategoryNews"));
const ReportPersonal = lazy(() => import("../pages/SyntheticReport/ReportPersonnal/ReportPersonal"));
const ReportIncome = lazy(() => import("../pages/SyntheticReport/ReportIncome/ReportIncome"));
const ReportDayOff = lazy(() => import("../pages/SyntheticReport/ReportIncome/ReportDayOff"));
const ReportPropose = lazy(() => import("../pages/SyntheticReport/ReportIncome/ReportPropose"));
const ReportPersonalAdd = lazy(() => import("../pages/SyntheticReport/ReportPersonnal/ReportPersonalAdd"));
const DocumentNew = lazy(() => import("../pages/News/NewList/News"));
const NewAdd = lazy(() => import("../pages/News/NewAdd/NewAdd"));
const NewUpdate = lazy(() => import("../pages/News/NewUpdate/NewUpdate"));
const Reset = lazy(() => import("../pages/System/Reset/index"));

// Quản lý trình duyệt
const DanhSachTrinhKy = lazy(() => import("../pages/QuanLyTrinhDuyet/TrinhKy/DanhSach"));
const TaoMoiTrinhKy = lazy(() => import("../pages/QuanLyTrinhDuyet/TrinhKy/TaoMoi"));
const DanhSachTrinhDuyet = lazy(() => import("../pages/QuanLyTrinhDuyet/TrinhDuyet/DanhSach"));
const TaoMoiTrinhDuyet = lazy(() => import("../pages/QuanLyTrinhDuyet/TrinhDuyet/TaoMoi"));
const TaoMoiTrinhDuyet2 = lazy(() => import("../pages/QuanLyTrinhDuyet/TrinhDuyet/TaoMoi2"));
const DanhSachPheDuyet = lazy(() => import("../pages/QuanLyTrinhDuyet/PheDuyet/DanhSach"));
const ChiTietPheDuyet = lazy(() => import("../pages/QuanLyTrinhDuyet/PheDuyet/ChiTiet"));
const DanhSachPheDuyetDemo = lazy(() => import("../pages/QuanLyTrinhDuyet/PheDuyet/DanhSachDemo"));

const LibList = lazy(() => import("../pages/Libs/LibList"));

export default function Router() {

  return (
    <div >
      <BrowserRouter>
        <ToastGlobal />
        <LoadingGlobal />
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path='/' element={<Login />} />
            {/* Home */}
            <Route path="/" element={<PrivateRoute element={<LayoutPortal />} />}>
              <Route path="/home" element={<PrivateRoute element={<InternalDocuments />} />}></Route>
              <Route path="/news" element={<PrivateRoute element={<NewsPage />} />}></Route>
              <Route path="/detail-tags/:tag" element={<PrivateRoute element={<TagNews />} />}></Route>
              <Route path="/detail-news/:slug" element={<PrivateRoute element={<NewsDetailPage />} />}></Route>
              <Route path="/detail-document-home/:id" element={<PrivateRoute element={<HomePortalView />} />} ></Route>
              <Route path="/internal-documents" element={<PrivateRoute element={<HomePortal />} />}></Route>
              <Route path="/search-document" element={<PrivateRoute element={<SearchDocument />} />}></Route>
              <Route path="/personal-portal" element={<PrivateRoute element={<PersonalPortal />} />}></Route>
              <Route path="/reset" element={<PrivateRoute element={<Reset />} />}></Route>
              
              {/* <Route path="/personnel-evaluation" element={<ReportPersonal />}></Route>
              <Route path="/personnel-evaluation-add" element={<ReportPersonalAdd />}></Route>
              <Route path="/report-income" element={<ReportIncome />}></Route>
              <Route path="/report-dayoff" element={<ReportDayOff />}></Route>
              <Route path="/report-propose" element={<ReportPropose />}></Route> */}
            </Route>

            {/* Quản lý trình duyệt */}
            <Route path="/" element={<PrivateRoute element={<LayoutQLTD />} />}>
              <Route path="/signing-management" element={<PrivateRoute element={<DanhSachTrinhKy />} />}></Route>
              <Route path="/signing-management/add" element={<PrivateRoute element={<TaoMoiTrinhKy />} />}></Route>
              <Route path="/request-management" element={<PrivateRoute element={<DanhSachTrinhDuyet />} />}></Route>
              <Route path="/request-management/add" element={<PrivateRoute element={<TaoMoiTrinhDuyet />} />}></Route>
              <Route path="/request-management/add2" element={<PrivateRoute element={<TaoMoiTrinhDuyet2 />} />}></Route>
              <Route path="/approval-management" element={<PrivateRoute element={<DanhSachPheDuyet />} />}></Route>
              <Route path="/approval-management/detail" element={<PrivateRoute element={<ChiTietPheDuyet />} />}></Route>
              <Route path="/approval-management-mobile-preview" element={<PrivateRoute element={<DanhSachPheDuyetDemo />} />}></Route>
            </Route>
            {/* --- End Quản lý trình duyệt */}
            
            <Route path="/" element={<PrivateRoute element={<LayOut />} />}>
              <Route path="/chart" element={<PrivateRoute element={<HomePage />} />}></Route>
              {/* Cá nhân */}
              <Route path="/personal" element={<PrivateRoute element={<Personal />} />}></Route>
              <Route path="/personal-update" element={<PrivateRoute element={<PersonalUpdate />} />}></Route>
              {/* Quản lý danh mục */}
              <Route path="/management-unit" element={<PrivateRoute element={<ManagementUnit />} />}></Route>
              <Route path="/management-document" element={<PrivateRoute element={<ManagementDocument />} />}></Route>
              <Route path="/issuing-unit" element={<PrivateRoute element={<IssuingUnit />} />}></Route>
              <Route path="/report-unit" element={<PrivateRoute element={<ManagementReport />} />}></Route>
              <Route path="/report-unit-add" element={<PrivateRoute element={<ManagementReportAdd />} />}></Route>
              <Route path="/report-unit-update/:id" element={<PrivateRoute element={<ManagementReportUpdate />} />}></Route>

              <Route path="/document-catalog" element={<PrivateRoute element={<DocumentCatalog />} />}></Route>
              <Route path="/document-catalog-add" element={<PrivateRoute element={<DocumentCatalogAdd />} />}></Route>
              <Route path="/document-catalog-update/:id" element={<PrivateRoute element={<DocumentCatalogUpdate />} />}></Route>

              <Route path="/nationwide-unit" element={<PrivateRoute element={<NationwideUnit />} />}></Route>
              <Route path="/departmental-unit" element={<PrivateRoute element={<DepartmentalUnit />} />}></Route>
              <Route path="/regional-unit" element={<PrivateRoute element={<RegionalUnit />} />}></Route>
              <Route path="/promulgating-unit" element={<PrivateRoute element={<PromulgatingUnit />} />}></Route>
              <Route path="/management-news" element={<PrivateRoute element={<ManagementNews />} />}></Route>

              {/* Quản lý tài liệu */}
              <Route path="/document-list/:donvi?/:id?" element={<PrivateRoute element={<DocumentList />} />}></Route>
              <Route path="/document-list-add" element={<PrivateRoute element={<DocumentListAdd />} />}></Route>
              <Route path="/document-list-update/:id" element={<PrivateRoute element={<DocumentListUpdate />} />}></Route>
              <Route path="/document-list-detail/:id" element={<PrivateRoute element={<HomePortalView />} />} ></Route>
              {/* Quản lý tin tức */}
              <Route path="/document-new" element={<PrivateRoute element={<DocumentNew />} />}></Route>
              <Route path="/document-new-add" element={<PrivateRoute element={<NewAdd />} />}></Route>
              <Route path="/document-new-update/:id" element={<PrivateRoute element={<NewUpdate />} />}></Route>
              <Route path="/news-detail/:slug" element={<PrivateRoute element={<DetailNewsPage />} />}></Route>

              {/* Quản lý thư viện */}
              <Route path="/lib-list" element={<PrivateRoute element={<LibList />} />}></Route>

              {/* Báo cáo */}
              <Route path="/upload-report" element={<PrivateRoute element={<UploadReport />} />}></Route>
              <Route path="/add-report" element={<PrivateRoute element={<ReportAdd />} />}></Route>
              <Route path="/page404" element={<PrivateRoute element={<Page404 />} />}></Route>
              <Route path="/update-report/:id" element={<PrivateRoute element={<ReportUpdate />} />}></Route>
              <Route path="/report-statistics/:id?" element={<PrivateRoute element={<ReportStatistics />} />}></Route>
              {/* FAQ */}
              <Route path="/management-faq" element={<PrivateRoute element={<ManagementFAQ />} />}></Route>
              <Route path="/category-faq" element={<PrivateRoute element={<CategoryFAQ />} />}></Route>
              {/* <Route path="/management-unit" element={<ManagementUnit />}></Route> */}
              {/* Quản trị hệ thống */}
              <Route path="/system-configuration" element={<PrivateRoute element={<SystemConfiguration />} />}></Route>
              <Route path="/setup-sendemail" element={<PrivateRoute element={<SetUpServeSendEmail />} />} />
              <Route path="/setup-email" element={<PrivateRoute element={<SetUpNotiEmail />} />} />
              <Route path="/management-role" element={<PrivateRoute element={<Role />} />}></Route>
              <Route path="/log-activity" element={<PrivateRoute element={<LogActivity />} />}></Route>
              <Route path="/management-role-add" element={<PrivateRoute element={<RoleAdd />} />}></Route>
              <Route path="/management-role-update/:id" element={<PrivateRoute element={<RoleInfomation />} />}></Route>
              <Route path="/user-list" element={<PrivateRoute element={<UserList />} />}></Route>
              <Route path="/user-group-list" element={<PrivateRoute element={<UserGroupList />} />}></Route>
              <Route path="/user-group-add" element={<PrivateRoute element={<UserGroupAdd />} />}></Route>
              <Route path="/user-group-update/:id" element={<PrivateRoute element={<UserGroupUpdate />} />}></Route>
              <Route path="/user-group/add-excel" element={<PrivateRoute element={<UserGroupAddExcel />} />}></Route>
              {/* Báo cáo đánh giá nhân sự */}
            </Route>
            <Route
              path="/*"
              element={
                <FailReload
                  description="Hãy kiểm tra URL và đảm bảo rằng bạn đã nhập đúng. Nếu URL chính xác, hãy kiểm tra cấu hình máy chủ và đảm bảo rằng tệp tương ứng tồn tại."
                  numberErr={404}
                  titleErr="KHÔNG TÌM THẤY TRANG"
                />
              }
            ></Route>
            <Route path="/401page" element={
              <FailReload
                description="Hãy kiểm tra URL và đảm bảo rằng bạn đã nhập đúng. Nếu URL chính xác, hãy kiểm tra cấu hình máy chủ và đảm bảo rằng tệp tương ứng tồn tại."
                numberErr={404}
                titleErr="KHÔNG TÌM THẤY TRANG"
              />}></Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

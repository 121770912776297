import React from 'react'
import { notFound_404 } from '../../components/imgExport'

interface ErrorProps {
    numberErr: number,
    titleErr: string,
    description: string,
}

const FailReload: React.FC<ErrorProps> = ({ numberErr, titleErr, description }) => {
    return (
        <div className='flex justify-center items-center row p-0 m-0' style={{ height: '100vh', width: '100vw' }}>
            <div className="flex flex-col items-center  justify-center col-md-4  gap-12 w-[40%]">
                <div className="col-8">
                    <img className='img' src={notFound_404} alt="" />
                </div>
                <div className="flex flex-col items-center  justify-center gap-2">
                    <h3 className='text-[#344054] font-[600] text-3xl'>{numberErr} - {titleErr}</h3>
                    <span className='text-[#98A2B3] text-sm mt-1 text-center'>{description}</span>
                </div>
            </div>
        </div>
    )
}


export default FailReload

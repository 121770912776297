
import { ResponseSuccessTotal } from "../axiosClient";
import axiosClientUser, { ResponsePostSuccess, ResponseSuccess } from "../axiosClientUser";

export interface DataTypeReport {
    key: string;
    stt: number;
    id: number;
    ten_file_bao_cao: string;
    tieu_de: string;
    ten_danh_muc: string;
    ma_don_vi: string;
    ten_don_vi: string;
    nguoi_tai_len: string;
    ngay_tai_len: string;
    nguoi_tao: string
    id_nguoi_tai_len: number | null
}

export interface DataBaoCao {
    id: number | null
    key: number,
    name: string,
    type: number | null,
    size: number | null,
    file: any,
}


export interface DataTypeReportStatistical {
    key: number;
    id: number | null;
    ten_file_bao_cao: string;
    baocao_id: number | null
    ma_don_vi: string;
    ten_don_vi: string;
    ten_danh_muc: string;
    nguoi_tai_len: string;
    ngay_tai_len: string;
    tinh_trang: boolean;
    tinh_trang_check: string;
}

export interface ListDanhMucBaoCao {
    id: number
    tieu_de: string
    bao_cao: string
    ngay_tai_len: string
    ngay_tai_len_format: string
    danhMucBaoCao: DanhMucBaoCao | null
    adminBaoCao: AdminBaoCao | null
    baocaotailen: BaoCaoTaiLen[]
}

export interface DanhSachThongKe {
    id: number
    danhmucbaocao_id: number
    ten_danh_muc_bao_cao: string
    bao_cao?: string
    tieu_de?: string
    baocao_id?: number
    fullname: string
    ngay_tai_len?: string
    ten_don_vi: string | null
    ma_don_vi: string | null
}

export interface DanhMucBaoCao {
    id: number
    ten_danh_muc_bao_cao: string
    nguoi_tao: NguoiTao | null
}

export interface NguoiTao {
    id: number
    fullname: string
}

export interface AdminBaoCao {
    id: number
    fullname: string
    admin_dv: Root2[]
}

export interface Root2 {
    id: number
    donvi_id: number
    admin_don_vi: AdminDonVi
}

export interface AdminDonVi {
    ten_don_vi: string
    ma_don_vi: string
    level: number
}


export interface DonViAdmin {
    id: number
    ten_don_vi: string
    ma_don_vi: string
}


export interface Pagination {
    current: number,
    pageSize: number,
    total: number,
}

export interface SelectOption {
    value: number;
    label: string;
}

export interface ThemBaoCao {
    tieu_de: string
    noi_dung: string
    danh_muc_bao_cao_id: number
    quyen_bao_cao: QuyenBaoCao[],
    bao_mat: boolean
}

export interface CapNhatBaoCao {
    id: number
    tieu_de: string
    noi_dung: string
    file_bao_cao?: any
    danh_muc_bao_cao_id: number
    quyen_bao_cao: QuyenBaoCao[],
    bao_mat: boolean
}

export interface QuyenBaoCao {
    admin_id: number;
}

export interface ChiTietBaoCao {
    id: number
    tieu_de: string
    bao_cao: string
    noi_dung: string
    danh_muc_bao_cao_id: number
    ngay_tai_len: string
    bao_mat: boolean
    status: boolean
    admin_id: number
    delf: boolean
    loai_file: number | null
    danhMucBaoCao: DanhMucBaoCao
    adminBaoCao: AdminBaoCao
    quyen_bao_cao: QuyenBaoCaoUpdate[]
    baocaotailen: BaoCaoTaiLen[]
}

export interface BaoCaoTaiLen {
    id: number
    ten: string
    kich_thuoc: number
    ngay_tai_len: string
    delf: boolean
    loaiFileBaoCao: LoaiFileBaoCao
  }
  
  export interface LoaiFileBaoCao {
    id: number
    loai_file: string
    file: string
    color: string
    image: string
    tai_lieu: boolean
    bao_cao: boolean
    delf: boolean
  }


export interface QuyenBaoCaoUpdate {
    id: number
    admin_id: number
}

export interface DanhMucBaoCao {
    id: number
    ten_danh_muc_bao_cao: string
}

export interface AdminBaoCao {
    id: number
    fullname: string
}

export interface BieuDoThongKe {
    chuagui: number
    dagui: number
}

export interface DanhMucBaoCaoChiaSe {
    dataChiaSe: DanhMuc[],
    dataCuaToi: DanhMuc[],
    totalChiaSe: 0,
    totalCuaToi: 0
}

export interface DanhMuc {
    id: number
    ma_danh_muc_bao_cao: string
    ten_danh_muc_bao_cao: string
    so_luong: number
}


export interface UploadReportData {
    id: number,
    title: string,
    totalDocument: number,
    monthReport: Data[]
}

export interface Data {
    id: number
    titleReport: string
}

export interface DataThongKeBaoCao {
    rows: DanhSachThongKe[]
    da_gui: number
    chua_gui: number
}


export const deleteBaoCao = async (id: number) => {
    const url = `baocao/xoa`;
    const params = { id }
    const result: ResponseSuccess<[]> = await axiosClientUser.delete(
        url, { params }
    );
    return result;
}

export const getReportList = async (PageNum: number, PageSize: number, search: string, ngay_bat_dau: string, ngay_ket_thuc: string, danh_muc: number[], donViBH: number[], type: number) => {
    const url = `baocao/danh-sach`;
    const don_vi: string = JSON.stringify(donViBH);
    const danh_muc_bao_cao: string = JSON.stringify(danh_muc);
    const params = {PageNum, PageSize, search, ngay_bat_dau, ngay_ket_thuc, danh_muc_bao_cao, type, don_vi }
    const result: ResponseSuccessTotal<ListDanhMucBaoCao> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}

export const getReportListAll = async (type: number) => {
    const url = `baocao/danh-sach-tat-ca`;
    const params = { type }
    const result: ResponseSuccess<ListDanhMucBaoCao[]> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}

export const createReport = async (
    tieu_de: string,
    noi_dung: string,
    data_bao_cao: Array<any> = [],
    danh_muc_bao_cao_id: number,
    bao_mat: boolean,
    quyen_bao_cao: Array<any> = [],
) => {
    const url = `baocao/them-moi`;
    const params = {
        tieu_de,
        noi_dung,
        data_bao_cao,
        danh_muc_bao_cao_id,
        bao_mat,
        quyen_bao_cao,
    }
    const result: ResponsePostSuccess = await axiosClientUser.post(
        url, params
    );
    return result;
}

export const detailReport = async (id: number) => {
    const url = `baocao/chi-tiet`;
    const params = { id }
    const result: ResponseSuccess<ChiTietBaoCao> = await axiosClientUser.get(url, { params });
    return result;
}

export const updateReport = async (
    id: number,
    tieu_de: string,
    noi_dung: string,
    data_bao_cao: Array<any> = [],
    danh_muc_bao_cao_id: number,
    bao_mat: boolean,
    quyen_bao_cao: Array<any> = [],
) => {
    const url = `baocao/cap-nhat`;
    const params = {
        id,
        tieu_de,
        noi_dung,
        data_bao_cao,
        danh_muc_bao_cao_id,
        bao_mat,
        quyen_bao_cao
    }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}

export const getReportStatistical = async (search: string, danh_muc: number[], tinh_trang: number | null, donViBH: number[], type: number) => {
    const url = `baocao/danh-sach-thong-ke`;
    const danh_muc_bao_cao: string = JSON.stringify(danh_muc);
    const donVi: string = JSON.stringify(donViBH);
    const params = { search, danh_muc_bao_cao , tinh_trang: tinh_trang === null ? 0 : tinh_trang, type, don_vi: donVi}
    const result: ResponseSuccess<DataThongKeBaoCao> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}

export const getReportStatisticalChart = async (search: string, danh_muc: number[], tinh_trang: number | null) => {
    const url = `baocao/bieu-do`;
    const danh_muc_bao_cao: string = JSON.stringify(danh_muc);
    const params = { search, danh_muc_bao_cao ,  tinh_trang: tinh_trang === null ? 0 : tinh_trang}
    const result: ResponseSuccess<BieuDoThongKe> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}

export const getDanhMucChiaSe = async () => {
    const url = `danhmucbaocao/danh-muc-chia-se`;
    const result: ResponseSuccess<DanhMucBaoCaoChiaSe> = await axiosClientUser.get(
        url, { }
    );
    return result;
}

export const deleteReportUpDown = async (id: number) => {
    const url = `baocao/xoa-tai-len-tai-ve`;
    const params = { id }
    const result: ResponseSuccess<[]> = await axiosClientUser.delete(
        url, { params }
    );
    return result;
}
import { Button } from 'antd'
import React from 'react'
import './styles.css'
interface Show {
    button: string,
    open: boolean
}
export const LoadingSpin: React.FC<Show> = ({ button, open }) => {
    return (
        <Button
            className='h-full w-full text-white  flex items-center justify-center custome-btne'
            type="text"
            loading={open}
            htmlType="submit"
        >
            {button}
        </Button>
    )
}

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorAlert, shiftAlert, TAlert } from '../store/slices/toastSlice';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ToastGlobal = () => {
    const dispatch = useDispatch();
    const { toasts } = useSelector(selectorAlert);
    const [alert, setAlert] = useState<TAlert>({message: "" , icon: "success", timer: 2000});
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        if (!isShow) {
            showNextToast();
        }
    }, [toasts])

    const showNextToast = () => {
        if (toasts.length > 0) {
            setAlert(toasts[0]);
            setIsShow(true);
            dispatch(shiftAlert());
            switch (toasts[0].icon) {
                case 'success':
                    toast.success(toasts[0].message, {theme: "colored"});
                    break;
                case 'error':
                    toast.error(toasts[0].message,{theme: "colored"});
                    break;
                case 'warn':
                    toast.warn(toasts[0].message);
                    break;
                case 'info':
                    toast.info(toasts[0].message);
                    break;
                default:
                    toast(toasts[0].message);
                    break;
            }
            setTimeout(() => {
                setIsShow(false);
            }, toasts[0].timer)
        }
    }
    return (
        <ToastContainer autoClose={alert.timer} theme="colored" />
    )
}

export default ToastGlobal;
import { ResponseSuccessTotal } from "../axiosClient";
import axiosClientUser, { ResponsePostSuccess, ResponseSuccess } from "../axiosClientUser";

export interface Pagination {
    current: number,
    pageSize: number,
    total: number,
}

export interface HinhAnh {
    id: number;
    ten: string;
    ngay_tai_len: Date;
}

export interface CreatHinhAnh {
    ten: string;
    file: any
}


export const getLibList = async (PageNum: number, PageSize: number, search: string) => {
    const url = `hinhanh/danh-sach`;
    const params = { PageNum, PageSize, search }
    const result: ResponseSuccessTotal<HinhAnh> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}

export const creatLib = async (data_hinh_anh: any,) => {
    const url = `hinhanh/them-moi`;
    const  params = { data_hinh_anh }
    const result: ResponsePostSuccess = await axiosClientUser.post(
        url, params
    );
    return result;
}

export const deleteLib = async (id: number) => {
    const url = `hinhanh/xoa`;
    const params = { id }
    const result: ResponsePostSuccess = await axiosClientUser.delete(
        url, { params }
    );
    return result;
}

export const detailLib = async (id: number) => {
    const url = `hinhanh/chi-tiet`;
    const params = { id }
    const result: ResponseSuccess<HinhAnh> = await axiosClientUser.get(
        url, { params }
    );
    return result;
}

export const updateLib = async (id: number, ten: string, file: any) => {
    const url = `hinhanh/cap-nhat`;
    const params = { id, ten, file }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}
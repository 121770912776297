
import { useSelector } from 'react-redux';
import { selectorLoading } from '../store/slices/loadingSlice';
import { Spin } from 'antd';
const LoadingGlobal = () => {
    const loading = useSelector(selectorLoading);
    return (
        <div>
            <Spin size="small" tip="Đang tải..." spinning={loading.show}/>
        </div>
    )
}
export default LoadingGlobal;
import '../styles.css'
import * as NotificationApi from '../../api/NotificationApi'
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useState } from "react";

interface AllNoti {
    handleScroll: (limit: number, offset: number, load: boolean) => void
    handleDetail: (tailieu_id: number | null, baocao_id: number | null, id: number, danhmucbaocao_id: number | null) => void
    allNotification: NotificationApi.ListThongBao[]
    totalNotifica: number
    setOffsetAll: (value: number) => void
    offsetAll: number
    limit: number
}

export const AllNotification: React.FC<AllNoti> = ({ allNotification, handleDetail, handleScroll, totalNotifica, limit, offsetAll, setOffsetAll }) => {
    let temp = null
    let [dex, setDex] = useState(1)
    if (temp) {
        clearTimeout(temp)
    }
    temp = setTimeout(() => {
        setDex(2)
    }, 1);
    return (
        <>
            {dex === 2 &&
                <div
                    id="scrollableDiv"
                    style={{
                        height: 240,
                        overflow: 'auto',
                    }}
                >
                    <InfiniteScroll
                        dataLength={allNotification.length}
                        next={() => {
                            handleScroll(limit, Number(offsetAll + 1), true);
                            setOffsetAll(offsetAll + 1)
                        }}
                        hasMore={allNotification.length < totalNotifica}
                        loader={<h4 style={{ textAlign: 'center' }}>Đang tải...</h4>}
                        scrollableTarget="scrollableDiv"
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Bạn đã xem tất cả thông báo</b>
                            </p>
                        }
                        refreshFunction={() => {
                            handleScroll(limit, 0, false);
                            setOffsetAll(0)
                        }}
                    >
                        {allNotification.map((item, k) => (
                            <div
                                key={k}
                                className="notification-item"
                                onClick={() => handleDetail(item.tailieu_id, item.baocao_id, item.id, item.danhmucbaocao_id)}
                            >
                                <div className='flex gap-3 items-start'>
                                    <img src="/static/media/icon-user.c1ae71e1abe5bdaadea1906c9558ca74.svg" alt="" />
                                    <div className="flex flex-col gap-1">
                                        <span className='text-[#1F1F1F] aspe custom-css-1-line'>
                                            <span className='text-[#1F1F1F] font-[500]'></span>{item.document}</span>
                                        <div className="flex gap-2 items-center">
                                            <span className='text-xs text-[#8E8E8E]'>{item.time}</span>
                                            <div className="h-1 w-1 rounded-full bg-[#8E8E8E]"></div>
                                            <span className='text-xs text-[#8E8E8E]'>{item.date}</span>
                                        </div>
                                    </div>
                                </div>
                                {item.status === false ? <div>
                                    <img className="fixdotnoti" src="/static/media/dots.015926b943efe17297d3e80777054058.svg" alt="" />
                                </div> : ""}
                            </div>
                        ))}
                    </InfiniteScroll>
                </div>
            }
        </>
    )
}

interface AllNotiUnread {
    handleScrollUnRead: (limit: number, offset: number, load: boolean) => void
    handleDetail: (tailieu_id: number | null, baocao_id: number | null, id: number, danhmucbaocao_id: number | null) => void
    allNotificationUnRead: NotificationApi.ListThongBao[]
    totalNotificaUnread: number
    setOffset: (value: number) => void
    offset: number
    limit: number
}

export const Unread: React.FC<AllNotiUnread> = ({ allNotificationUnRead, handleDetail, handleScrollUnRead, totalNotificaUnread, limit, offset, setOffset }) => {
    return (
        <div
            id="scrollableDiv1"
            style={{
                height: 240,
                overflow: 'auto',
            }}
        >
            <InfiniteScroll
                dataLength={allNotificationUnRead.length}
                next={() => {
                    handleScrollUnRead(limit, Number(offset + 1), true);
                    setOffset(offset + 1)
                }}
                hasMore={allNotificationUnRead.length < totalNotificaUnread}
                loader={<h4 style={{ textAlign: 'center' }}>Đang tải...</h4>}
                scrollableTarget="scrollableDiv1"
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Bạn đã xem tất cả thông báo</b>
                    </p>
                }
                refreshFunction={() => {
                    handleScrollUnRead(limit, 0, false);
                    setOffset(0)
                }}
            >
                {allNotificationUnRead.map((item, k) => (
                    <div
                        key={k}
                        className="notification-item"
                        onClick={() => handleDetail(item.tailieu_id, item.baocao_id, item.id, item.danhmucbaocao_id)}
                    >
                        <div className='flex gap-3 items-start'>
                            <img src="/static/media/icon-user.c1ae71e1abe5bdaadea1906c9558ca74.svg" alt="" />
                            <div className="flex flex-col gap-1">
                                <span className='text-[#1F1F1F] aspe custom-css-1-line'>
                                    <span className='text-[#1F1F1F] font-[500]'></span>{item.document}</span>
                                <div className="flex gap-2 items-center">
                                    <span className='text-xs text-[#8E8E8E]'>{item.time}</span>
                                    <div className="h-1 w-1 rounded-full bg-[#8E8E8E]"></div>
                                    <span className='text-xs text-[#8E8E8E]'>{item.date}</span>
                                </div>
                            </div>
                        </div>
                        {item.status === false ? <div>
                            <img className='fixdotnoti' src="/static/media/dots.015926b943efe17297d3e80777054058.svg" alt="" />
                        </div> : ""}
                    </div>
                ))}
            </InfiniteScroll>
        </div>
    )
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserSlice {
  userPrkID: string
  userID: string
  userName: string | null
  userEmail: any
  userCapQlyPrkID: string
  userGrpPrkID: string
  isActive: string
  isSystem: string
  donVi: number[]
}


const initialState: UserSlice = {
  userPrkID: "",
  userID: "",
  userName: "",
  userEmail: null,
  userCapQlyPrkID: "",
  userGrpPrkID: "",
  isActive: "False",
  isSystem: "False",
  donVi: []
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserSlice>) => action.payload,
    setUserName: (state, action) => {
      state.userName = action.payload
    },
    setUserDonVi: (state, action) => {
      state.donVi = action.payload
    },
    clearUser: (state) => initialState
  }
})

export const selectorUser = (state: { user: UserSlice }) => state.user;
export const selectorUserId = (state: { user: UserSlice }) => state.user.userPrkID;
export const selectorDonVi = (state: { user: UserSlice }) => state.user.donVi;

// export const { setUser, setUserAvatar, setUserId, setUserName, clearUser } = userSlice.actions;
export const { setUser, setUserName, clearUser, setUserDonVi } = userSlice.actions;
export default userSlice.reducer;
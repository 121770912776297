const getToken = () => {
    return localStorage.getItem('token');
}
const setToken = (token: string) => {
    localStorage.setItem('token', token);
}


const getMenu = () => {
    return localStorage.getItem('menu');
}
const setMenu = (menu:string) => {
    localStorage.setItem('menu', menu);
}
const removeToken = () => {
    localStorage.removeItem('token');
}

export default {
    getToken, setToken, removeToken, setMenu,  getMenu
}
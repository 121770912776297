
import './App.css';
import './index.css';
import Router from './routes/Router';
import './pages/Theme/custom.css'
import './App.scss';
const App: React.FC = () => {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;

import { Button, Input, Modal, Upload, UploadProps } from 'antd';
import React, { useEffect, useState } from 'react';
import useToast from '../../../hook/useToast';
import { CloseOutlined, InboxOutlined } from "@ant-design/icons";
import { } from '../../../api/LibApi/index';
import * as LibApi from '../../../api/LibApi/index'

interface UploadModal {
  openUpload: boolean;
  closeUpload: () => void;
  fetchCategoryApi: () => void;
}
const { Dragger } = Upload;

export const ModalUpload: React.FC<UploadModal> = ({ openUpload, closeUpload, fetchCategoryApi }) => {
  const pushAlert = useToast();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [data, setData] = useState<any[]>([])

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload: () => false,
    fileList: data,
    onChange(info) {
      const { status, type, uid } = info.file;
      if (status === 'removed') {
        setData(prevState => prevState.filter(file => file.uid !== uid));
      } else {
        setData(prevState => [...prevState, info.file]);
      }
    },
    onDrop(e) {
      const files = e.dataTransfer.files;
      Array.from(files).forEach(file => {
        const fileType = file.type;
        if (fileType?.startsWith('image/')) {
          // Process the image file here
          setData(prevState => [...prevState, file]);
        }
      });
    },
  };
  const convertToBase64 = async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSaveUpload = async () => {
    if (!data || data.length <= 0) {
      pushAlert("Vui lòng chọn hình ảnh", "error", 2000);
    } else {
      try {
        const data_hinh_anh = await Promise.all(data.map(async (item) => {
          const base64String = await convertToBase64(item);
          const newItem: LibApi.CreatHinhAnh = {
            file: base64String,
            ten: item.name,
          };
          return newItem;
        }));
        const result = await LibApi.creatLib(data_hinh_anh);
        setLoadingSpin(true);
        if (result.success) {
          pushAlert("Thêm mới thành công!", "success", 2000)
          fetchCategoryApi();
          closeUpload();
          setData([])
          setLoadingSpin(false);
        } else {
          pushAlert(result.message ?? "Thêm mới không thành công", "error", 2000)
          setLoadingSpin(false);
        }
      } catch (error: any) {
        setLoadingSpin(false);
        pushAlert(error.message ?? "", "error", 2000)
      }
    }
  }

  useEffect(() => {
    setData([])
  }, [openUpload])
  return (
    <div className="">
      <Modal
        open={openUpload}
        onCancel={closeUpload}
        title="Tải ảnh lên"
        footer={[<Button key="back" onClick={closeUpload}>
          Hủy
        </Button>,
        <Button className='fix-bg-button' key="submit" type="primary" onClick={handleSaveUpload}>
          Lưu
        </Button>]}
        className="libr-modalupload"
      >
        <div className="modalupload">
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Nhấp hoặc kéo tệp vào khu vực này để tải lên</p>
            <p className="ant-upload-hint">Hỗ trợ tải lên một lần hoặc hàng loạt.</p>
          </Dragger>
        </div>
      </Modal>
    </div>
  )
}

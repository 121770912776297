import { Input, Modal } from 'antd'
import './styles.css'
import { CloseOutlined, CopyOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import * as SystemConfigurationApi from '../../../../../api/SystemConfigurationApi/index';
import { LoadingSpin } from '../../../../../components/LoadingSpin';
import { useEffect, useState } from 'react';
import useToast from '../../../../../hook/useToast';
import * as LibApi from '../../../../../api/LibApi/index'
import useDebounce from '../../../../../hook/useDebounce';
import Library from '../../../../../components/Library';

import env from "../../../../../env";
import {
  ClassicEditor, Bold, Essentials, Italic, Paragraph, ButtonView, ImageUtils, ImageInline, Plugin,
  Heading, FontFamily, FontSize, FontColor, FontBackgroundColor,
  Strikethrough, Subscript,
  Link, BlockQuote, TodoList, Indent,
  Table, TableToolbar,
  ImageResize, icons,
  SourceEditing,
  Image, ImageToolbar, ImageCaption, ImageStyle, LinkImage
}
from 'ckeditor5';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import 'ckeditor5/ckeditor5.css';

import { click } from '@testing-library/user-event/dist/click';

const { TextArea } = Input;
let showModalLb = null as any
let editor = null as any
class ImageCustom extends Plugin {
  init() {
      editor = this.editor;
      editor.ui.componentFactory.add( 'Imagecustom', () => {
      const button = new ButtonView();
      button.set( {
        icon: icons.image,
      })
      button.on( 'execute', () => {
        showModalLb()
      })
      return button;
    })
  }
}
export interface ModalProps {
    open: boolean;
    handleClose: () => void;
    dataChiTiet: SystemConfigurationApi.EmailTemplate | undefined
    listParamDocument: SystemConfigurationApi.Param[]
    listParamReport: SystemConfigurationApi.Param[]
    listParamDuThao: SystemConfigurationApi.Param[]
}

export default function SetUpNotificationEmail(modal: ModalProps) {
    let errorsObj = {
        tieu_de: "",
        noi_dung: "",
    };
    const { open, handleClose, dataChiTiet, listParamDocument, listParamReport, listParamDuThao } = modal
    const [loadingSpin, setLoadingSpin] = useState<boolean>(false)
    const [errors, setErrors] = useState(errorsObj);
    const [idEmail, setIdEmail] = useState<number>(0);
    const [tieuDe, setTieuDe] = useState<string>("");
    const [noiDung, setNoiDung] = useState<string>("");
    const [type, setType] = useState<number | null>(null)
    const pushAlert = useToast();

    const UpdateSystem = async (e: any) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (tieuDe === "") {
            errorObj.tieu_de = "Vui lòng nhập tiêu đề";
            error = true;
        }
        if (noiDung.trim() === "") {
            errorObj.noi_dung = "Vui lòng nhập nội dung";
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return null;
        } else {
            try {
                const result = await SystemConfigurationApi.updateEmailTemplate(idEmail, tieuDe, noiDung);
                setLoadingSpin(true);
                if (result.success) {
                    pushAlert("Cập nhật thành công!", "success", 2000)
                    handleClose();
                    setLoadingSpin(false);
                } else {
                    pushAlert('Cập nhật không thành công' ?? "", "error", 2000)
                    setLoadingSpin(false);
                }
            } catch (error: any) {
                setLoadingSpin(false);
                pushAlert(error.message ?? "", "error", 2000)
            }
        }
    };

    function uploadPlugin(editor: any) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
            return uploadAdapter(loader);
        };
    }

    function uploadAdapter(loader: any) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: any) => {
                    });
                });
            }
        };
    }
    useEffect(() => {
        setIdEmail(dataChiTiet?.id ?? 0);
        setTieuDe(dataChiTiet?.tieu_de ?? "");
        setNoiDung(dataChiTiet?.noi_dung ?? "");
        setType(dataChiTiet?.type ??null);
        setLoadingSpin(false)
    }, [open, dataChiTiet]);
    const copytext = (text: string) => () => {
        navigator.clipboard.writeText(text);
        pushAlert("Copy thành công", "success", 2000)
    }
    const [activeParam, setActiveParam] = useState<boolean>(false)
    useEffect(() => {
        document.addEventListener('click', function (event: any) {
            if (event.target.matches('.mail-params , .mail-params  *') === false) {
                setActiveParam(false)
            }
        })
    }, [])

    const [pagination, setPagination] = useState<LibApi.Pagination>({ current: 1, pageSize: 30, total: 0 });
    const fetchCategoryApi = async (pageCurent: number, pageSize: number, search: string) => {
        try {
          let result = await LibApi.getLibList(pageCurent, pageSize, search.trim());
          if (result?.success) {
            setDataDanhSachLib(result.data.rows);
            setPagination({ current: pageCurent, pageSize: pageSize, total: result.data.count })
          }
        } catch (error) {
          console.log(error);
        }
    }

    const [search, setSearch] = useState<string>('');
    const [dataImage, setDataImage] = useState<string>('')
    const handleChangeList = useDebounce((value: string) => {
        setSearch(value)
        fetchCategoryApi(1, 30, value)
    }, 1000)

    useEffect(() => {
        Promise.all([
          fetchCategoryApi(1, 30, ""),
          setSearch("")
        ])
      }, [])
    const handlePaginationChange = (page: number, size: number) => {
        fetchCategoryApi(page, size, search);
    };
    const [dataDanhSachLib, setDataDanhSachLib] = useState<LibApi.HinhAnh[]>([]);
    const changeImage = (value: any) => {
    const img = dataDanhSachLib.find((item) => item.id == value)?.ten ?? ''
    const imageUtils = editor?.plugins.get( 'ImageUtils' );
    imageUtils.insertImage( { src: `${env.process.env.IMAGE_LIB_URL}${img}` } );
    }
    const [showlb, setShowlb] = useState(0)
        showModalLb = () => {
        setShowlb(Date.now())
    }

    return (
        <Modal
            title={
                <div className='flex items-center gap-3 pb-3'>
                    <div className='border-r-EEEEEE cursor' onClick={handleClose}>
                        <CloseOutlined className='text-[#8E8E8E] pr-4' />
                    </div>
                    {dataChiTiet?.ten}
                </div>
            }
            centered
            open={open}
            onCancel={handleClose}
            footer={[]}
            className="custom-modal"
            width={1000}
            closeIcon={false}
        >
            <div className="flex flex-col ">
                <div className='flex flex-col py-[18px]  border-b-t-EEEEEE gap-4'>
                    <div className='head-form mail-template-content'>
                        <div className='input-title'>
                            <Input placeholder="Tiêu đề" value={tieuDe} onChange={(e) => setTieuDe(e.target.value)} />
                            {errors.tieu_de && (
                                <div className="text-danger fs-12">
                                    {errors.tieu_de}
                                </div>
                            )}
                        </div>
                        <div className={`mail-params ${activeParam ? 'active' : ''}`}>
                            <div onClick={() => setActiveParam(!activeParam)} className='lp-title text-save text-white rounded-[4px] overflow-hidden w-[106px] bg-[#096DD9] ant-btn ant-btn-text h-full w-full text-white  flex items-center custome-btne'>
                                <span>Danh sách params</span>
                                {activeParam === false ? <DownOutlined className='icon-d' /> : <UpOutlined className='icon-d' />}
                            </div>
                            <div className='lot-x'>
                                <div className='list-data'>
                                    {type && type === 0 ? listParamReport && listParamReport.map((item, index) => {
                                        return (
                                            <div className='lp-item' key={index}>
                                                <div className='lb-info'>
                                                    <div className='label'>{item.title}</div>
                                                    <div className='value'>{item.value}</div>
                                                </div>
                                                <div className='icon' onClick={copytext(item.value)}>
                                                    <CopyOutlined />
                                                </div>
                                            </div>
                                        )
                                    }) : type && type === 1 ? listParamDocument && listParamDocument.map((item, index) => {
                                        return (
                                            <div className='lp-item' key={index}>
                                                <div className='lb-info'>
                                                    <div className='label'>{item.title}</div>
                                                    <div className='value'>{item.value}</div>
                                                </div>
                                                <div className='icon' onClick={copytext(item.value)}>
                                                    <CopyOutlined />
                                                </div>
                                            </div>
                                        )
                                    }) : listParamDuThao && listParamDuThao.map((item, index) => {
                                        return (
                                            <div className='lp-item' key={index}>
                                                <div className='lb-info'>
                                                    <div className='label'>{item.title}</div>
                                                    <div className='value'>{item.value}</div>
                                                </div>
                                                <div className='icon' onClick={copytext(item.value)}>
                                                    <CopyOutlined />
                                                </div>
                                            </div>
                                        )
                                    }) }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-none'>
                    <Library
                        isShow={showlb}
                        onChange={(e) => changeImage(e)}
                        className='w-[200px] chose-img-for-ckedit'
                        option={{ aspectRatio: '10/7' }}
                        dataDanhSachLib={dataDanhSachLib}
                        pagination={pagination}
                        imageSelect={dataImage}
                        handleChangeList={handleChangeList}
                        handlePaginationChange={handlePaginationChange}
                    />
                    </div>
                    <div className='modal-textarea'>
                        <CKEditor
                            editor={ClassicEditor}
                            data={noiDung}
                            onChange={(event, editor) => {
                                setNoiDung(editor.getData())
                            }}
                            onBlur={(event, editor) => {
                                console.log(event);

                            }}
                            onReady={ClassicEditor => { }}
                            config={ {
                                plugins: [
                                  Heading, Essentials, Bold, Italic, Paragraph, ImageUtils, ImageInline, ImageCustom,
                                  FontFamily, FontSize, FontColor,
                                  FontBackgroundColor, Strikethrough, Subscript,
                                  Link, BlockQuote, TodoList, Indent, Table, TableToolbar,
                                  ImageResize, SourceEditing,
                                  Image, ImageToolbar, ImageCaption, ImageStyle, LinkImage
                                ],
                                toolbar: [
                                  'undo', 'redo',
                                  '|',
                                  'heading',
                                  '|',
                                  'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                  '|',
                                  'bold', 'italic', 'strikethrough', 'subscript',
                                  '|',
                                  'link', 'blockQuote',
                                  '|',
                                  'todoList', 'outdent', 'indent', 'insertTable', 'Imagecustom', 'sourceEditing'
                                ],
                                image: {
                                  toolbar: [
                                    'imageStyle:block',
                                    'imageStyle:side',
                                    '|',
                                    'toggleImageCaption',
                                    'imageTextAlternative',
                                    '|',
                                    'linkImage'
                                  ],
                                  insert: {
                                    type: 'auto'
                                  }
                                }
                            }}
                        />
                    </div>
                    {errors.noi_dung && (
                        <div className="text-danger fs-12">
                            {errors.noi_dung}
                        </div>
                    )}
                </div>
                <div className='border-t-EEEEEE pt-4'>
                    <div className="flex justify-end items-center flex-wrap">
                        <div className="w-full flex items-center gap-8 justify-end">
                            <span onClick={handleClose} className='  text-sm font-[500] cursor-pointer'>Hủy</span>
                            <div className={`text-save text-white rounded-[4px] overflow-hidden w-[106px]  bg-[#096DD9] ${loadingSpin ? ' pointer-events-none' : ''}`} onClick={UpdateSystem}>
                                <LoadingSpin open={loadingSpin} button="Lưu" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

import { Checkbox, Input, Select } from 'antd';
import '../styles.css';
import { BreadcrumbComponent } from '../../../components/BreadcrumbComponent';
import { MailOutlined } from '@ant-design/icons';
import * as SystemConfigurationApi from '../../../api/SystemConfigurationApi/index';
import { useEffect, useState } from 'react';
import { convertBytesSystem, convertToKilobytes } from '../../../utils';
import useToast from '../../../hook/useToast';
import { useNavigate } from 'react-router-dom';
import { IMG_URL } from '../../../env';

export default function SystemConfiguration() {
    const [dataLoaiFile, setDataLoaiFile] = useState<SystemConfigurationApi.LoaiFile[]>([]);
    const [dataLoaiFileBaoCao, setDataLoaiFileBaoCao] = useState<SystemConfigurationApi.LoaiFile[]>([]);
    const [selectLoaiFileTaiLieu, setSelectLoaiFileTaiLieu] = useState<number[]>([]);
    const [selectLoaiFileBaoCao, setSelectLoaiFileBaoCao] = useState<number[]>([]);
    const [dungLuongTaiLieu, setDungLuongTaiLieu] = useState<number>(0);
    const [dungLuongTaiLieuGoc, setDungLuongTaiLieuGoc] = useState<number>(0);
    const [email, setEmail] = useState<string>("");
    const [selectLoaiDungLuongTL, setSelectLoaiDungLuongTL] = useState<string>("KB");
    const [selectLoaiDungLuongTLGoc, setSelectLoaiDungLuongTLGoc] = useState<string>("KB");
    const [selectLoaiDungLuongBC, setSelectLoaiDungLuongBC] = useState<string>("KB");
    const [dungLuongBaoCao, setDungLuongBaoCao] = useState<number>(0);
    const pushAlert = useToast();
    const navigator = useNavigate();

    useEffect(() => {
        fetchLoaiFile()
        fetchCauHinh()
    }, [])
    const fetchLoaiFile = async () => {
        try {
            const result = await SystemConfigurationApi.getListLoaiFile();
            if (result.success) {
                const dataTL = result.data.filter((item) => item.tai_lieu === false)
                const dataBC = result.data.filter((item) => item.bao_cao === false)
                setDataLoaiFile(dataTL)
                setDataLoaiFileBaoCao(dataBC)
            }
        } catch (error) {
        }
    }

    const fetchCauHinh = async () => {
        try {
            const result = await SystemConfigurationApi.getCauHinh();
            if (result.success) {
                const dungluong_tl = convertBytesSystem(result.data.dung_luong_tai_lieu)
                const dungluong_bc = convertBytesSystem(result.data.dung_luong_bao_cao)
                const dungluong_tl_goc = convertBytesSystem(result.data.dung_luong_tai_lieu_goc)
                setSelectLoaiFileTaiLieu(result.data.tailieu)
                setSelectLoaiFileBaoCao(result.data.baocao)
                //
                setDungLuongTaiLieu(Number(dungluong_tl.byte))
                setSelectLoaiDungLuongTL(dungluong_tl.unit)
                //
                setDungLuongBaoCao(Number(dungluong_bc.byte))
                setSelectLoaiDungLuongBC(dungluong_bc.unit)
                //
                setDungLuongTaiLieuGoc(Number(dungluong_tl_goc.byte))
                setSelectLoaiDungLuongTLGoc(dungluong_tl_goc.unit)
                setEmail(result.data.email)
            }
        } catch (error) {
        }
    }

    const handleChangeTaiLieu = (value: boolean, id: number) => {
        if (value) {
            setSelectLoaiFileTaiLieu([...selectLoaiFileTaiLieu, Number(id)]);
        } else {
            if (selectLoaiFileTaiLieu.filter((item) => item !== Number(id)).length > 0) {
                setSelectLoaiFileTaiLieu(selectLoaiFileTaiLieu.filter((item) => item !== Number(id)));
            }
        }
    }

    const handleChangeBaoCao = (value: boolean, id: number) => {
        if (value) {
            setSelectLoaiFileBaoCao([...selectLoaiFileBaoCao, Number(id)]);
        } else {
            if (selectLoaiFileBaoCao.filter((item) => item !== Number(id)).length > 0) {
                setSelectLoaiFileBaoCao(selectLoaiFileBaoCao.filter((item) => item !== Number(id)));
            }
        }
    }

    const handleSaveCauHinh = async () => {
        if (email === "") {
            pushAlert("Vui lòng nhập email nhận thông báo!", "error", 2000);
        } else {
            const dungluongTL = convertToKilobytes(dungLuongTaiLieu, selectLoaiDungLuongTL)
            const dungluongTLGoc = convertToKilobytes(dungLuongTaiLieuGoc, selectLoaiDungLuongTLGoc)
            const dungluongBC = convertToKilobytes(dungLuongBaoCao, selectLoaiDungLuongBC)
            try {
                const result = await SystemConfigurationApi.updateCauHinh(selectLoaiFileTaiLieu, selectLoaiFileBaoCao, dungluongTL, dungluongBC, email, dungluongTLGoc);
                if (result.success) {
                    pushAlert("Cập nhật thành công!", "success", 2000);
                    navigator("/system-configuration")
                } else {
                    pushAlert("Cập nhật không thành công!", "error", 2000);
                }
            } catch (error) {
                pushAlert("Cập nhật không thành công!", "error", 2000);
            }
        }

    }
    const handleInputChangeTL = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const regex = /^[0-9\b]+$/; // Chỉ cho phép nhập số

        if (value === '' || regex.test(value)) {
            setDungLuongTaiLieu(Number(value));
        }
    };

    const handleInputChangeBC = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const regex = /^[0-9\b]+$/; // Chỉ cho phép nhập số
        if (value === '' || regex.test(value)) {
            setDungLuongBaoCao(Number(value));
        }
    };
    return (
        <div className='flex flex-col h-full bg-[#F5F6FA]'>
            <BreadcrumbComponent children='Cấu hình hệ thống' title='Cấu hình hệ thống' />
            <div className=" px-6 py-6">
                <div className={`flex flex-col bg-white rounded-md  pt-2 w-full `}>
                    <div className="border-EEEEEE pb-2">
                        <h3 className='text-base font-[600] m-0 px-5 text-[#4B4B4B]'>Cấu hình</h3>
                    </div>
                    <div className='pt-[24px]'>
                        <div className='d-document  px-5'>
                            <span className='text-[14px] text-[#1F1F1F]'>Tài liệu</span>
                            <div className='flex flex-wrap gap-8  '>
                                {dataLoaiFile.map((item) =>
                                    <Checkbox
                                        className='flex items-center'
                                        key={item.id}
                                        checked={selectLoaiFileTaiLieu.includes(item.id) ? true : false}
                                        onChange={(e) => handleChangeTaiLieu(e.target.checked, item.id)}
                                    >
                                        <div className='flex gap-1 items-center'>
                                            <img src={IMG_URL + item.image} alt={item.image} />
                                            <p className='mb-0'>{item.file}</p>
                                        </div>
                                    </Checkbox>
                                )}
                            </div>
                        </div>
                        <div className='flex justify-end pt-4 pr-6'>
                            <div className='flex gap-6  items-center justify-end'>
                                <span className='text-[#4B4B4B] whitespace-nowrap'>Dung lượng tối đa</span>
                                <Input
                                    size="large"
                                    placeholder=""
                                    className='w-[25%]'
                                    value={dungLuongTaiLieu}
                                    onChange={(e) => handleInputChangeTL(e)}
                                />
                                <Select
                                    size="large"
                                    style={{ width: 120 }}
                                    value={selectLoaiDungLuongTL}
                                    options={[
                                        { value: 'KB', label: 'KB' },
                                        { value: 'MB', label: 'MB' },
                                    ]}
                                    onChange={setSelectLoaiDungLuongTL}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='pt-[24px]'>
                        <div className='d-document px-5'>
                            <span className='text-[14px] text-[#1F1F1F] whitespace-nowrap'>Báo cáo</span>
                            <div className='flex flex-wrap gap-8  '>
                                {dataLoaiFileBaoCao.map((item) =>
                                    <Checkbox
                                        className='flex items-center'
                                        key={item.id}
                                        checked={selectLoaiFileBaoCao.includes(item.id) ? true : false}
                                        onChange={(e) => handleChangeBaoCao(e.target.checked, item.id)}
                                    >
                                        <div className='flex gap-1 items-center'>
                                            <img src={IMG_URL + item.image} alt={item.image} />
                                            <p className='mb-0'>{item.file}</p>
                                        </div>

                                    </Checkbox>
                                )}
                            </div>
                        </div>
                        <div className='flex justify-end pt-4 pr-6'>
                            <div className='flex gap-6  items-center justify-end'>
                                <span className='text-[#4B4B4B] whitespace-nowrap'>Dung lượng tối đa</span>
                                <Input
                                    size="large"
                                    placeholder="2"
                                    className='w-[25%]'
                                    value={dungLuongBaoCao}
                                    onChange={handleInputChangeBC}
                                />
                                <Select
                                    size="large"
                                    style={{ width: 120 }}
                                    value={selectLoaiDungLuongBC}
                                    options={[
                                        { value: 'KB', label: 'KB' },
                                        { value: 'MB', label: 'MB' },
                                    ]}
                                    onChange={setSelectLoaiDungLuongBC}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='py-[24px]'>
                        <div className='flex flex-col px-5 gap-[32px]'>
                            <div className='d-notification '>
                                <span className='text-[14px] text-[#1F1F1F]'>Email nhận thông báo</span>
                                <div className='flex w-input-notification  '>
                                    <Input size="large" placeholder="Nhập Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className='d-notification'>
                                <span className='text-[14px] text-[#1F1F1F]'>Mẫu emal thông báo</span>
                                <div className='flex items-center w-establish rounded-[8px] border-096DD9 p-2 cursor-pointer'
                                    onClick={() => navigator('/setup-email')}
                                >
                                    <MailOutlined className='text-base text-[#096DD9] pr-1' />
                                    <span className='text-base text-[#096DD9]'>Thiết lập mẫu email thông báo</span>
                                </div>
                            </div>
                            <div className='d-notification'>
                                <span className='text-[14px] text-[#1F1F1F]'>Email gửi thông báo từ hệ thống</span>
                                <div className='flex items-center w-establish rounded-[8px] border-096DD9 p-2 cursor-pointer'
                                    onClick={() => navigator('/setup-sendemail')}>
                                    <MailOutlined className='text-base text-[#096DD9] pr-1' />
                                    <span className='text-base text-[#096DD9]'>Thiết lập máy chủ gửi email thông báo</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='px-5 py-3 custom-shadow'>
                        <button className='bg-[#227CDD] text-white text-base py-2 px-8 rounded-[6px]' onClick={handleSaveCauHinh}>
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


import { ResponseSuccessTotal } from "../axiosClient";
import axiosClientUser, { ResponsePostSuccess } from "../axiosClientUser";

export interface Pagination {
    current: number,
    pageSize: number,
    total: number,
}

export interface ThongBao {
    id: number
    noidung: string
    tieude: string
    status: boolean
    ngay_tao: string
    tailieu_id: number | null
    baocao_id: number | null
    danhmucbaocao_id: number | null
    loaiThongBaoTL: LoaiThongBaoTl | null
    loaiThongBaoBC: LoaiThongBaoBC | null
    ThongBaoAdmin: ThongBaoAdmin
  }
  
  export interface ListThongBao {
    id: number,
    tailieu_id: number | null
    baocao_id: number | null
    danhmucbaocao_id: number | null
    name: string,
    document: string,
    time: string,
    date: string,
    status: boolean
  }


  export interface LoaiThongBaoTl {
    id: number
    tieu_de: string
  }
  
  export interface LoaiThongBaoBC {
    id: number
    tieu_de: string
  }

  export interface ThongBaoAdmin {
    id: number
    fullname: string
  }
  
export const getNotification = async (PageNum: number, PageSize: number,) => {
    const url = `thongbao/danh-sach`;
    const params = { PageNum, PageSize}
    const result: ResponseSuccessTotal<ThongBao> = await axiosClientUser.get(
        url, {params}
    );
    return result;
}

export const getNotificationUnRead = async (PageNum: number, PageSize: number,) => {
    const url = `thongbao/danh-sach-chua-doc`;
    const params = { PageNum, PageSize}
    const result: ResponseSuccessTotal<ThongBao> = await axiosClientUser.get(
        url, {params}
    );
    return result;
}

export const updateNotification = async (
    id: number,
) => {
    const url = `thongbao/cap-nhat-trang-thai`;
    const params = {
        id,
    }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}

export const updateNotificationAll = async () => {
    const url = `thongbao/cap-nhat`;
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url,
    );
    return result;
}

export const updateNotificationView = async (id: number) => {
    const url = `thongbao/cap-nhat-xem`;
    const params = { id }
    const result: ResponsePostSuccess = await axiosClientUser.put(
        url, params
    );
    return result;
}